import { htmlToText } from "html-to-text"
import React from "react"
import Layout, { Container } from "."
import SEO from "../SEO"
import Slanted, { NormalHeading } from "./slanted"

const GenericPage = ({ data }) => {
  let imageData = null
  let summary = null
  let description = null
  const { content } = data
  if (typeof data.featuredImage !== undefined && data.featuredImage) {
    imageData = data.featuredImage.node.localFile.childImageSharp.fluid
  }
  if (typeof data.pageFields.summary !== undefined) {
    summary = data.pageFields.summary
    description = htmlToText(summary)
  }

  return (
    <Layout>
      <SEO title={data.title} image={imageData} description={description}></SEO>
      <Slanted imageData={imageData}>
        <NormalHeading white>{data.title}</NormalHeading>
        {summary && (
          <div className="text-white text-shadow-contrast md:w-3/4">
            <div dangerouslySetInnerHTML={{ __html: summary }} />
          </div>
        )}
      </Slanted>
      <Slanted innerClass="bg-white">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </Slanted>
    </Layout>
  )
}

export default GenericPage
