import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import GenericPage from "../components/layout/genericPage"
import Slanted from "../components/layout/slanted"

const Why = ({ data: { wpPage } }) => {
  return <GenericPage data={wpPage} />
}

export default Why

export const query = graphql`
  query whyQuery {
    wpPage(slug: { eq: "varfor-rosta-i-kyrkovalet" }) {
      title
      content
      pageFields {
        summary
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
